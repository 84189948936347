<template>
  <div class="container-fluid" ref="container" v-if="!_.isEmpty(order)">
    <div class="Content">
      <div class="text-center">
        <img src="~/assets/images/catapultaLogo.png" class="w-50" />
      </div>

      <div class="row mt-2" v-if="!hideCustomerInfo">
        <div class="col-6">
          <p>
            <b>{{ $t('customer') }}</b>
          </p>
          <table>
            <tr
              v-for="[key, value] in customerProps"
              :key="key"
              v-if="!_.isUndefined(value)"
            >
              <td>{{ $t(key) }}:</td>
              <td class="pl-4">{{ value }}</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <p>
            <b>{{ $t('delivery') }}</b>
          </p>
          <table>
            <tr v-for="[key, value] in deliveryProps" :key="key">
              <td>{{ $t(key) }}:</td>
              <td class="pl-4">{{ value }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-6 alignOnCenterLeft">
          <h1 class="mb-2">{{ $t('invoice') }}</h1>
          <p>
            <b class="mr-2">{{ $t('order') }}:</b> {{ order | orderCode }}
          </p>
          <p>
            <b class="mr-2">{{ $t('date') }}:</b>
            {{ order.date | formatDateTime('date') }}
          </p>
        </div>

        <div class="col-6" v-if="providerView">
          <template v-if="!itemView">
            <p>
              <b>{{ $t('additionalInfos') }}</b>
            </p>
            <table>
              <tr v-for="[key, value] in otherProps" :key="key">
                <td>{{ $t(key) }}:</td>
                <td class="pl-4">{{ value }}</td>
              </tr>
            </table>
          </template>
        </div>
        <div class="col-6 Total p-4 alignOnCenterLeft" v-else>
          <h1 class="mb-2">{{ order | orderTotalValue | money }}</h1>
          <p>{{ $t('thankYouForYourPurchase') }}!</p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 el-table">
          <table class="w-100">
            <colgroup>
              <col class="w-50" />
            </colgroup>
            <thead>
              <tr>
                <th :colspan="providerView ? 3 : 1">{{ $t('description') }}</th>
                <th v-if="!providerView">{{ $t('price') }}</th>
                <th>{{ $t('quantity') }}</th>
                <th v-if="!providerView" class="text-right">
                  {{ $t('total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.items" :key="item.id">
                <td :colspan="providerView ? 3 : 1">
                  <div class="mb-2">
                    {{ item.product.name }}
                  </div>
                  <div v-for="attrVal in item.attributes" :key="attrVal.id">
                    <b class="mr-2">{{ attrVal.attribute.caption }}:</b>
                    <span>{{ attrVal.caption }}</span>
                  </div>
                </td>
                <td v-if="!providerView">{{ item.price | money }}</td>
                <td>{{ item.quantity | number }}</td>
                <td class="text-right" v-if="!providerView">
                  {{ (item.price * item.quantity) | money }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 el-table">
              <table>
                <tbody class="TableFooter">
                  <tr v-if="!providerView">
                    <td colspan="2">
                      <b>{{ $t('subtotal') }}</b>
                    </td>
                    <td class="text-right">
                      {{ orderSubtotal | money }}
                    </td>
                  </tr>
                  <tr v-if="!providerView">
                    <td colspan="2">
                      <b>{{ $t('deliveryPrice') }}</b>
                    </td>
                    <td class="text-right">
                      {{ _.get(order, 'shipments[0].total', 0) | money }}
                    </td>
                  </tr>
                  <tr v-if="!providerView">
                    <td class="text-nowrap">
                      <b>{{ $t('productionDeadlineDelivery') }}</b>
                    </td>
                    <td colspan="2" class="text-right">
                      {{
                        _.get(order, 'productionDays', 15) +
                        _.get(order, 'shippingDays', 10)
                      }}
                      {{ $t('workingDays').toLowerCase() }} ({{
                        $t('afterPayment').toLowerCase()
                      }})
                    </td>
                  </tr>
                  <tr v-if="!providerView">
                    <td colspan="2">
                      <b>{{ $t('total') }}</b>
                    </td>
                    <td class="text-right">
                      {{ order | orderTotalValue | money }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <template v-if="itemView && order.items[0]">
        <div v-if="order.items[0].design" class="ItemDesign">
          <div v-if="order.items[0].design.imageFileId" class="text-center">
            <img
              :src="order.items[0].design.imageFileId | uploadUrl"
              alt="Item design"
            />
          </div>

          <pre
            v-if="order.items[0].design.description"
            class="text-center mt-4 DesignDescription"
            v-html="order.items[0].design.description"
          />
        </div>
      </template>
    </div>

    <div v-if="!itemView" class="Footer mt-5 pt-2">
      <div class="row">
        <div class="col-6 alignOnCenter">
          <p>{{ $t('trackYourOrderThroughTheSite') }}:</p>
          <p>
            <a
              href="https://catapultapersonalizados.com.br/minha-conta/pedidos"
            >
              https://catapultapersonalizados.com.br/minha-conta/pedidos
            </a>
          </p>
        </div>
        <div class="col-6 alignOnCenter">
          {{ $t('cnpj') }}: 28.076.425/0001-01
        </div>
      </div>

      <template v-if="providerView">
        <OrderMailTagExportPageContent :order="order" class="mb-3" :hide-customer-info="hideCustomerInfo"/>
      </template>
    </div>
  </div>
</template>

<script>
import Badge from 'components/Badge';
import { formatDateTime } from 'util/utils';
import OrderMailTag from 'pages/order/OrderMailTag';
import OrderMailTagExportPageContent from 'pages/order/OrderMailTagExportPageContent';
import { OrderUi } from 'api/Models';

export default {
  name: 'OrderExportPage',
  components: { OrderMailTagExportPageContent, OrderMailTag, Badge },
  props: {
    orderId: {},
    tempStorageKey: {},
    providerView: {},
    itemView: {},
    hideCustomerInfo: {},
  },
  data() {
    return {
      order: null,
    };
  },
  methods: {
    async fetchOrder() {
      this.loading = true;
      try {
        this.order = {};
        if (this.tempStorageKey) {
          this.order = new OrderUi(
            await this.$api.TempStorage.get(this.tempStorageKey)
          );
        } else if (this.orderId) {
          this.order = await this.$api.Order.findById(this.orderId, {
            eager: `[
            shipments.[method],
            items.[product.[attributes], attributes.[attribute], design.[designer], provider],
            payments,
            customer.[addresses, contacts, discountCoupons],
            status.[types],
            designChangeUser,
            seller,
            appliedCoupons,
            recommendedByQuiz.[order.[customer]]
          ]`,
          });
          const itemId = _.parseInt(this.$route.query.reqItem || '0');
          if (itemId) {
            this.reqItem = this.order.items.find((i) => i.id === itemId) || {};
          }
        }
      } finally {
        this.loading = false;
      }
    },
    calculateSubtotal(order) {
      if (!order) {
        return 0;
      }

      return (order.items || []).reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
    },
  },
  computed: {
    orderSubtotal() {
      return this.calculateSubtotal(this.order);
    },
    customerProps() {
      const order = this.order;
      const isLegal = _.get(order, 'customer.type', 'NATURAL') === 'LEGAL';
      const props = {
        name: _.get(order, 'customer.name'),
        cpf: isLegal ? undefined : _.get(order, 'customer.cpf', '-'),
        cnpj: isLegal ? _.get(order, 'customer.cnpj', '-') : undefined,
        stateRegistration: isLegal
          ? _.get(order, 'customer.props.stateRegistration', '-')
          : undefined,
        phone: _.get(order, 'customer.contacts[0].content', '-'),
        email: _.get(order, 'customer.email', '-'),
      };
      return Object.entries(props);
    },
    deliveryProps() {
      const order = this.order;
      return Object.entries({
        shippingMethod: _.get(order, 'shipments[0].method.description', '-'),
        address: _.get(order, 'customer.addresses[0].address', '-'),
        neighborhood: _.get(order, 'customer.addresses[0].neighborhood', '-'),
        postalCode: _.get(order, 'customer.addresses[0].postalCode', '-'),
      });
    },
    otherProps() {
      const order = this.order;
      return Object.entries({
        productionDeadline:
          formatDateTime(order.productionDeadline, 'date') || '-',
        eventDate: formatDateTime(order.eventDate, 'date') || '-',
        provider:
          order.items
            .filter((item) => !!item.provider)
            .map((item) => item.provider.name)
            .join(', ') || '-',
      });
    },
  },
  async mounted() {
    const body = document.querySelector('body');
    body.classList.add('white-content');
    body.classList.add('forPrint');
    await this.fetchOrder();
    setTimeout(function () {
      window._PAGE_LOAD_FINISHED = true;
    }, 2000);
  },
  updated() {
    if (this.$route.query.exportType === 'pdf') {
      const prop = this.$refs.container.offsetHeight / window.innerHeight;
      if (prop > 1) {
        this.$refs.container.style.minHeight = `${100 * Math.ceil(prop)}vh`;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '~assets/sass/dashboard/custom/variables';

.container {
  min-height: 100vh;
  position: relative;
}

.Footer {
  border-top: 2px solid;
}

.Total {
  background-color: #f04f53;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Total * {
  color: white;
}

.TableFooter {
  border-top: 3px solid;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.alignOnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alignOnCenterLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.el-table td {
  border-top-width: 3px;
}

.Content,
.el-table,
.el-table th {
  font-size: $font-size-xl;
}

.TableFooter tr:first-child td {
  border-top-width: 0;
}

.el-table td,
.el-table th {
  border-color: #d3d4d9 !important;
  page-break-inside: avoid;
}

.ItemDesign img {
  max-width: 95%;
}

.DesignDescription {
  font-family: inherit;
  font-size: 2rem;
}
</style>
